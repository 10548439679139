<template>
  <div class="carousel">
    <div class="carousel-inner">
      <slot></slot>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>  
.carousel {
    overflow: hidden;
    position: relative;
}
.carousel-inner {
    display: flex;
    transition: transform 0.3s ease;
    animation: slide 8s infinite; /* 轮播动画 */
}
@keyframes slide {
    0% { transform: translateX(0); }
    25% { transform: translateX(-0%); }
    50% { transform: translateX(-100%); }
    75% { transform: translateX(-200%); }
    100% { transform: translateX(-300%); }
}
</style>
